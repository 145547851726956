
export class EntityFactura {
  codsii;
  esSeleccionado;
  fecdoc;
  fecven;
  nomcte;
  numinv;
  rutcte;
  saldo;
  tipoDocto;
  total;

  /**
   * @param codsii {string}
   * @param fecdoc {string}
   * @param fecven {string}
   * @param nomcte {string}
   * @param numinv {number}
   * @param rutcte {number}
   * @param saldo {number}
   * @param tipoDocto {string}
   * @param total {number}
   */
  constructor(
    codsii,
    fecdoc,
    fecven,
    nomcte,
    numinv,
    rutcte,
    saldo,
    tipoDocto,
    total
  ) {
    this.codsii = codsii;
    this.esSeleccionado = false;
    this.fecdoc = new Date(fecdoc);
    this.fecven = new Date(fecven);
    //this.fecdoc =  new Date (fecdoc).toLocaleString("es-CL").slice(0, 10);
    
   // this.fecven = new Date (fecven).toLocaleString("es-CL").slice(0, 10);
   // lo deje asi aqui ya que se caia la app
    // this.fecdoc = (fecdoc.slice(0, 10));
    // this.fecven = (fecven.slice(0, 10));
    this.nomcte = nomcte;
    this.numinv = numinv;
    this.rutcte = rutcte;
    this.saldo = saldo;
    this.tipoDocto = tipoDocto;
    this.total = total;
  }
}

export class DtoResConsultarFactura {
  codsii;
  fecdoc;
  fecven;
  nomcte;
  numinv;
  rutcte;
  saldo;
  tipoDocto;
  total;

  /**
   * @param codsii {string}
   * @param fecdoc {string}
   * @param fecven {string}
   * @param nomcte {string}
   * @param numinv {number}
   * @param rutcte {number}
   * @param saldo {number}
   * @param tipoDocto {string}
   * @param total {number}
   */
  constructor(
    codsii,
    fecdoc,
    fecven,
    nomcte,
    numinv,
    rutcte,
    saldo,
    tipoDocto,
    total
  ) {
    this.codsii = codsii;
    this.fecdoc = fecdoc;
    this.fecven = fecven;
    this.nomcte = nomcte;
    this.numinv = numinv;
    this.rutcte = rutcte;
    this.saldo = saldo;
    this.tipoDocto = tipoDocto;
    this.total = total;
  }
}

/**
 * @param argumentos {Array<DtoResConsultarFactura>}
 * @returns {Array<EntityFactura>}
 */
export function AdapterEntityFactura(argumentos) {
  let response = [];
  for (let row of argumentos) {
    response.push(
      new EntityFactura(
        row.codsii,
        row.fecdoc,
        row.fecven,
        row.nomcte,
        row.numinv,
        row.rutcte,
        row.saldo,
        row.tipoDocto,
        row.total
      )
    );
  }

  return response;
}
