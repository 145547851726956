import React from "react";
import SolicitaRut from "../pages/solicitaRut/SolicitaRut";
import Navbar from "../layout/navbar/navbar";
import Footer from "../layout/footer/footer";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import ListadoFacturas from "../pages/listadoFacturas/ListadoFacturas";
import PagoExitoso from "../pages/pagoExitoso/PagoExitoso";
import PagoRechazado from "../pages/pagoRechazado/PagoRechazado";
import "./Main.css";

function RoutesApp() {
  return (
    <>
      <HashRouter>
        <Navbar />
        <div className="main">
          <Routes>
            <Route exact path="/" element={<SolicitaRut />} />
            <Route exact path="/solicita-rut" element={<SolicitaRut />} />
            <Route
              exact
              path="/listado-facturas/:rut"
              element={<ListadoFacturas />}
            />
            <Route exact path="/pago-exitoso" element={<PagoExitoso />} />
            <Route exact path="/pago-rechazado" element={<PagoRechazado />} />
          </Routes>
        </div>
      </HashRouter>
      <Footer />
    </>
  );
}

export default RoutesApp;
