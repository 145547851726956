import axios from "axios";

export async function KhipuResponse(
  saldo,
  currency,
  numeroOrden,
  numeroDocumento
) {
  const response = await axios.get(
    `${process.env.REACT_APP_API}Consumo_api_khipu/${saldo}/${currency}/${numeroOrden}/${numeroDocumento}`
  );
  return response.data;
}
