import React from "react";
import Head from "./components/Head";
import "./ListadoFacturas.css";
import Grid from "@mui/material/Grid";
import Table from "./components/Table/Table";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { ListadoFacturasApi } from "../../services/prixus/ConsumoApi";
import Parrafo from "../../componentes/paragraph/paragraph";
import { KhipuResponse } from "../../services/khipu/ConsumoApiKhipu";
import { KhipuOrdenPedido } from "../../services/khipu/ConsumoOrdenPedido";
import { khipuInsertaOrdenDetalle } from "../../services/khipu/ConsumoOrdenPedidoDetalle";
import { AdapterEntityFactura } from "./models";
import { getCheckDigit } from "rut.js";

function ListadoFacturas() {
  const [Data, setData] = useState(AdapterEntityFactura([]));
  const [isLoadingTable, setIsLoadingTable] = useState(true);

  let { rut } = useParams();

  const llamar = async () => {
    let rest = await ListadoFacturasApi(rut);
    let responseAdapter = AdapterEntityFactura(rest);
    setData(responseAdapter);

    setIsLoadingTable(false);
  };

  let digitoVerificador = getCheckDigit(rut);
  useEffect(() => {
    if (rut) llamar();
  }, [rut]);

  const LlamadaApi = async () => {
    const value = Data.filter((row) => row.esSeleccionado === true).reduce(
      (total, row) => total + row.saldo,
      0
    );
    const arays = Data.filter((row) => row.esSeleccionado === true).map(
      (row) => row.numinv
    );
    const fechaVencimiento = Data.filter(
      (row) => row.esSeleccionado === true
    ).map((row) => row.fecven.toISOString().split("T")[0]);

    const tipoDoc = Data.filter((row) => row.esSeleccionado === true).map(
      (row) => row.codsii
    );
    const montoIndividualFactura = Data.filter(
      (row) => row.esSeleccionado === true
    ).map((row) => row.saldo);
    const currency = "CLP";

    let ResponseOrdenPago = await KhipuOrdenPedido(0, value, 0);
    let ordenPago = ResponseOrdenPago[0].ingreso;

    let result = await KhipuResponse(value, currency, ordenPago, arays);

    let url = result["payment_url"];
    let paymentID = result["payment_id"];

    let updateOrdenPago = await KhipuOrdenPedido(paymentID, value, ordenPago);
    console.log("fechas", fechaVencimiento);
    for (let i = 0; i < arays.length; i++) {
      let updateOrdenDetalle = await khipuInsertaOrdenDetalle(
        ordenPago,
        rut,
        digitoVerificador,
        tipoDoc[i],
        arays[i],
        fechaVencimiento[i],
        montoIndividualFactura[i]
      );
    }
    paymentID = "";
    window.open(url, "_self");
  };

  return (
    <Grid className="cardPago" container spacing={0}>
      <Grid item xs={8} md={8} lg={12}>
        <h1 className="title">Paga tu cuenta</h1>
        <div className="datospersonales">
          <div className="step">
            <p className="nombreusuario">
              <strong>Cliente: </strong>
              {!!Data.length ? Data[0].nomcte : ""}
            </p>
          </div>
          <div className="step">
            <p className="nombreusuario">
              <strong>Rut: </strong>
              {useParams().rut} - {getCheckDigit(useParams().rut)}
            </p>
          </div>
        </div>
        <div className="espacio"></div>
        <div className="divider" />
        <Head number="1" subtitle="Seleccione sus facturas" />
        <div className="divider" />

        <Table data={Data} setData={setData} isLoading={isLoadingTable} />
      </Grid>

      <Grid
        container
        justifyContent={"flex-end"}
        style={{ textAlign: "right" }}
      >
        <Parrafo
          title={`Total a pagar: $ ${Data.filter(
            (row) => row.esSeleccionado === true
          )
            .reduce((total, row) => total + row.saldo, 0)
            .toLocaleString("es-CL")}`}
          clase="total"
          id="total"
        />
      </Grid>
      <Grid item xs={12} md={12} className={"text-center"}>
        <div className="boton">
          <button
            type="button"
            className={"myButton"}
            onClick={() => LlamadaApi()}
          >
            Pagar con khipu
          </button>
        </div>
      </Grid>
    </Grid>
  );
}

export default ListadoFacturas;
