import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "./Input.css";
export default function TextFields({
  label,
  variant,
  color,
  error,
  textError,
  value,
  maxLength,
  onChange,
}) {
  return (
    <Box component="form" autoComplete="off">
      <TextField
        className="input"
        label={label}
        variant={variant}
        color={color}
        value={value}
        error={error}
        helperText={textError}
        inputProps={{ maxLength: maxLength }}
        onChange={onChange}
      />
    </Box>
  );
}
