import axios from "axios";

/**
 * @param RUT {string}
 * @returns {Promise<Array<DtoResConsultarFactura>>}
 */
export async function ListadoFacturasApi(RUT) {
  const response = await axios.get(
    `${process.env.REACT_APP_API}/saldoDocumentos/${RUT}`
    //`${process.env.REACT_APP_API}obtener_analisis_cta_cte/01-01-1900/01-01-2050/${RUT}/${RUT}/110402/110402/s/0/1/0`
  );
  return Array.isArray(response.data) ? response.data : [];
}
