import React from "react";
import Boton from "../../componentes/button/button";
import Titulo from "../../componentes/title/title";
import Parrafo from "../../componentes/paragraph/paragraph";
import { Grid } from "@mui/material";
import "./PagoRechazado.css";
import Rejected from "../../resources/rejected.png";
function PagoRechazado() {
  const pulsar = () => {
    let url = "https://prixuserp.com";
    window.open(url, "_self");
  };
  return (
    <>
      <div className="box">
        <Grid container xs={12} lg={10}>
          <Grid item xs={1} md={2} />
          <Grid item xs={10} md={8}>
            <Grid container>
              <Grid item xs={12}>
                <img
                  src={Rejected}
                  className="Image"
                  alt="Rejected"
                  border="0"
                />
                <Titulo title="Pago Rechazado" clase="black" />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Parrafo
                  clase="negro"
                  title="Hubo un problema al procesar su pago, intentelo nuevamente"
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={1} md={2} />
              <Grid item xs={12} md={12} lg={12} className={"text-center"}>
                <p></p>
                <Boton
                  variant="contained"
                  value="Aceptar"
                  clase="boton-completo"
                  onClick={() => pulsar()}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} md={2} />
        </Grid>
      </div>
    </>
  );
}
export default PagoRechazado;
