import axios from "axios";

export async function khipuInsertaOrdenDetalle(
  ORDENPAGO,
  RUT,
  DV,
  TIPODOC,
  NUMERODOC,
  FECHA,
  MONTO
) {
  const response = await axios.get(
    `${process.env.REACT_APP_API}khipuInsertaOrdenDetalle/${ORDENPAGO}/${RUT}/${DV}/${TIPODOC}/${NUMERODOC}/${FECHA}/${MONTO}`
  );
  return response.data;
}
