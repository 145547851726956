import React from 'react'

function head({number, subtitle}) {
  return (
    <div className='head'>
            <div className='number'>
                <h3>{number}</h3>
            </div>
            <div className='subtitle'>
                <h3>{subtitle}</h3>
            </div>
    </div >
  )
}

export default head