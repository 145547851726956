import Titulo from "../../componentes/title/title";
import TextField from "../../componentes/input/input";
import Parrafo from "../../componentes/paragraph/paragraph";
import { Grid } from "@mui/material";
import "./SolicitaRut.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../resources/Isotipo.png";

export default function SolicitaRut() {
  const { validateRUT } = require("validar-rut");
  const { validate, format } = require("rut.js");
  const [RUT, setRUT] = useState("");
  const [validTextRUT, setValidTextRUTT] = useState({
    error: false,
    message: "",
  });

  useEffect(() => {}, [RUT]);
  const url = process.env.REACT_APP_LISTAR_FACTURAS;

  const navigate = useNavigate();

  const pulsar = () => {
    if (RUT.length === 0) {
      setValidTextRUTT({ error: true, message: "" });
    }
    if (RUT.length > 0) {
      let formatterRUT =
        RUT.length === 10 ? format(RUT) : JSON.parse(JSON.stringify(RUT));

      const rut = validateRUT(RUT);

      if (rut) {
        formatterRUT = formatterRUT
          .split("-")[0]
          .replace(/[.]/g, "")
          .substr(0, 8);
        if (formatterRUT.indexOf("0") === 0) {
          formatterRUT = formatterRUT
            .split("-")[0]
            .replace(/[.]/g, "")
            .substr(1, 8);
        }
        navigate(url + formatterRUT);
      } else {
        const revalidacionRut = validate(formatterRUT);

        if (revalidacionRut) {
          formatterRUT = formatterRUT
            .split("-")[0]
            .replace(/[.]/g, "")
            .substr(0, 8);
          if (formatterRUT.indexOf("0") === 0) {
            formatterRUT = formatterRUT
              .split("-")[0]
              .replace(/[.]/g, "")
              .substr(1, 8);
          }
          navigate(url + formatterRUT);
        } else {
          setValidTextRUTT({ error: true, message: "RUT no valido" });
        }
      }
    }
  };

  return (
    <>
      <Grid container>
        <Grid item xs={1} md={2} />
        <Grid item xs={10} md={8}>
          <div className="box">
            <Grid container>
              <Grid item xs={12}>
                <img src={Logo} className="Image" alt="Logo" border="0" />
                <Titulo title="Pago fácil Prixus ERP" clase="black" />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Parrafo
                  clase="negro"
                  title="Ingresa tu RUT para continuar con el pago de tu cuenta"
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  className="input"
                  label="Ingresa tu RUT"
                  variant="outlined"
                  color="success"
                  value={RUT}
                  error={validTextRUT.error}
                  textError={validTextRUT.message}
                  onChange={(e) => {
                    let value = e.target.value;
                    let regexTest = new RegExp(
                      "^[0-9]{0,2}[.]{0,1}[0-9]{0,3}[.]{0,1}[0-9]{0,3}[-]{0,1}[0-9kK]{0,1}$",
                      "g"
                    );
                    if (regexTest.test(value)) {
                      let rutFormat = value.replace(/[.-]/g, "");
                      rutFormat = `${rutFormat.substring(0, 2)}${
                        rutFormat.length > 2 ? "." : ""
                      }${rutFormat.substring(2, 5)}${
                        rutFormat.length > 5 ? "." : ""
                      }${rutFormat.substring(5, 8)}${
                        rutFormat.length > 8 ? "-" : ""
                      }${rutFormat.substring(8, 9)}`.trim();
                      setValidTextRUTT({ error: false, message: "" });
                      setRUT(rutFormat);
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={1} md={2} />
              <Grid item xs={12} md={12} className={"text-center"}>
                <p></p>
                <div className="button-container">
                  <button
                    type="button"
                    className={"myButton"}
                    onClick={() => pulsar()}
                  >
                    Ver deuda
                  </button>
                </div>
              </Grid>
            </Grid>
          </div>
          <div className="box2">
            <div className="row">
              <Grid>
                <h3 className="text-center">Forma de pago:</h3>
              </Grid>
              <img
                src="https://www.khipu.com/wp-content/uploads/2022/03/08-Khipu-200x75-1.svg"
                className="Image-footer"
                alt="Logo-1"
                border="0"
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={1} md={2} />
      </Grid>
    </>
  );
}
