import React from "react";
import "./navbar.css";
import Logo from "../../resources/LOGO.png";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
function navbar() {
  return (
    <Grid item xs={1} sm={2} lg={9}>
      <div className="navbar">
        <img className="logo" src={Logo} alt="prixus" />
        <ul className="list">
          <li className="listItem">
            <Link underline="none" color="inherit" href="https://prixuserp.com">
              Volver
            </Link>
          </li>
        </ul>
      </div>
    </Grid>
  );
}

export default navbar;
