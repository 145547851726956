
import RoutesApp from './route/RoutesApp.jsx';
import './App.css';

function App() {

  return (

        <div>
          <RoutesApp />
        </div>

  );
}

export default App;
