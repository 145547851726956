import React from "react";
import "./Table.css";
import { useParams } from "react-router-dom";
import Buscando from "../../../../resources/buscando.gif";
import { getCheckDigit } from "rut.js";

/**
 * @param {{data: Array<any>,setData:Function,isLoading:boolean}} props Propiedad del componente Table
 */
function Table({ data, setData, isLoading }) {
  const handledChange = (row, checked) => {
    row.esSeleccionado = checked;
    let newData = [...data];
    let index = newData.findIndex((subrow) => subrow.numinv === row.numinv);
    if (index !== -1) {
      newData.splice(index, 1, row);
    }
    setData(newData);
  };

  const pulsar = (checked) => {
    if (checked) {
      let newData = [...data];
      newData.forEach((row) => {
        row.esSeleccionado = true;
      });

      setData(newData);
    } else {
      let newData = [...data];
      newData.forEach((row) => {
        row.esSeleccionado = false;
      });
      setData(newData);
    }
  };

  return (
    <>
      <div className="dataHead">
        <div className="tit">
          <input
            type="checkbox"
            id="ckeck"
            className="encabezados"
            onChange={(e) => pulsar(e.target.checked)}
          />
          <div className="encabezados">Tipo docto</div>
          <div className="encabezados">Número docto</div>
          <div className="encabezados">Estado</div>
          <div className="encabezados">Vencimiento</div>
          <div className="encabezados">Monto</div>
        </div>
        <div className="table">
          {isLoading ? (
            <div className="data">
              <div className="data-row">
                <img src={Buscando} alt="cagando..." width="60px" />
              </div>
            </div>
          ) : data.length > 0 ? (
            data.map((row) => (
              <div className="data" key={row.numinv}>
                <div className="data-row">
                  <div className="cell checkbox">
                    <input
                      type="checkbox"
                      id="ckeck"
                      checked={row.esSeleccionado}
                      onChange={(evt) => handledChange(row, evt.target.checked)}
                    />
                  </div>
                  <div className="cell tipoDocto">{row.tipoDocto}</div>
                  <div className="cellNumeroDoc">{row.numinv}</div>
                  <div className="cellNumero">
                    {new Date().getTime() > row.fecven.getTime()
                      ? "Vencido"
                      : "Vigente"}
                  </div>
                  <div className="cell">
                    {row.fecven?.toLocaleString("es-CL").slice(0, 10) || ""}
                  </div>
                  <div className="cellMonto">
                    {"$" + row.saldo.toLocaleString("es-CL")}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="data">
              <div className="data-row">
                <div className="cell">Sin facturas pendientes</div>
              </div>
            </div>
          )}
        </div>

        {/*<div>
                    {
                        data.map(data => {
                            return (
                                <div className='data' key={data.id}>

                                    <div className='detalles'>
                                        {data.facturas.map(fact => {
                                            return (
                                                <div className='cell' key={fact.id}>
                                                    <input type="checkbox" />
                                                </div>

                                            )
                                        }
                                        )}
                                    </div>
                                    <div className='detalles'>
                                        {data.facturas.map(fact => {
                                            return (
                                                <div className='cell tipoDocto'>
                                                    {fact.tipdoc}
                                                </div>
                                            )
                                        }
                                        )}
                                    </div>
                                    <div className='detalles'>
                                        {data.facturas.map(fact => {
                                            return (
                                                <div className='cell'>
                                                    {fact.numdoc}
                                                </div>
                                            )
                                        }
                                        )}
                                    </div>
                                    <div className='detalles'>
                                        {data.facturas.map(fact => {
                                            return (
                                                <div className='cell'>
                                                    {fact.fecven}
                                                </div>
                                            )
                                        }
                                        )}
                                    </div>
                                    <div className='detalles'>
                                        {data.facturas.map(fact => {
                                            return (
                                                <div className='cell'>
                                                    {fact.mount}
                                                </div>
                                            )
                                        }
                                        )}
                                    </div>
                                </div>


                            )
                        })
                    }
                </div>*/}
      </div>
    </>
  );
}

export default Table;

{
  /* <div>
{
    data?.map(data => {
        return (
            <div className='data' key={data.id}>
                {data?.facturas.map(fact => {
                    return (
                        <div className='data-row' key={fact.id}>
                            <div className='cell' key={fact.id}>
                                <input type="checkbox" />
                            </div>
                            <div className='cell tipoDocto'>
                                {fact.tipdoc}
                            </div>
                            <div className='cell'>
                                {fact.numdoc}
                            </div>
                            <div className='cell'>
                                {fact.fecven}
                            </div>
                            <div className='cell'>
                                {fact.mount}
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    })
} */
}
