import React from "react";
import Boton from "../../componentes/button/button";
import Titulo from "../../componentes/title/title";
import Parrafo from "../../componentes/paragraph/paragraph";
import { Grid } from "@mui/material";
import "./PagoExitoso.css";
import Success from "../../resources/success.png";

function PagoExitoso() {
  const pulsar = () => {
    let url = "https://prixuserp.com";
    window.open(url, "_self");
  };
  return (
    <>
      <div className="box">
        <Grid container xs={12}>
          <Grid item xs={1} md={2} />
          <Grid item xs={10} md={8}>
            <Grid container>
              <Grid item xs={12}>
                <img src={Success} className="Image" alt="Success" border="0" />
                <Titulo title="Pago aceptado" clase="black" />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12}>
                <Parrafo
                  clase="negro"
                  title="Su pago se a procesado correctamente"
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item xs={1} md={2} />
              <Grid item xs={12} md={12} lg={12} className={"text-center"}>
                <p></p>
                <Boton
                  variant="contained"
                  value="Aceptar"
                  clase="boton-completo"
                  onClick={() => pulsar()}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} md={2} />
        </Grid>
      </div>
    </>
  );
}

export default PagoExitoso;
