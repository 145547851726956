import React from "react";
import "./footer.css";
import Logo from "../../resources/LogoBlanco.png";
import Face from "../../resources/face.png";
import Insta from "../../resources/insta.png";
import Linked from "../../resources/linkedin.png";

export default function footer() {
  return (
    <div className="footer">
      <div className="card">
        <div className="cardItem">
          <img className="logo" src={Logo} alt="" />

          <p className="text">
            Con nuestro software podrá hacer crecer su empresa o negocio a
            niveles que jamás imaginó.
          </p>
          <p className="text">Prixus ERP, sabemos como.</p>
          <p className="text">Redes Sociales</p>
          <div className="rrss">
            <ul className="list">
              <li className="listItem">
                <a href="https://www.facebook.com/PrixusErp">
                  <img className="img" src={Face} alt="facebook" />
                </a>
              </li>
              <li className="listItem">
                <a href="https://www.instagram.com/prixuserp/">
                  <img
                    className="img"
                    src={Insta}
                    alt="instagram"
                    size="20px"
                  />
                </a>
              </li>
              <li className="listItem">
                <a href="https://www.linkedin.com/company/prixuscl/">
                  <img className="img" src={Linked} alt="linkedin" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="card card-right">
        <h2 className="menu">Menú</h2>
        <ul className="listRight">
          <li className="listRight">
            <a className="listRight" href="https://prixuserp.com/sistema-erp/">
              ERP
            </a>
          </li>
          <li>
            <a
              className="listRight"
              href="https://prixuserp.com/software-contable/"
            >
              CONTADORES
            </a>
          </li>
          <li>
            <a
              className="listRight"
              href="https://prixuserp.com/punto-de-venta/"
            >
              PUNTO DE VENTA
            </a>
          </li>
          <li>
            <a className="listRight" href="https://prixuserp.com/contacto/">
              COTIZAR
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}
